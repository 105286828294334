import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import AutoComplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'semantic-ui-react'
// Components
import {
  Seo,
  Primary as SaveButton,
  Loading,
  PageHeader,
  CountryDropdown,
  StyledSelect,
  StyledInput,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Hooks
import useShowError from '../hooks/useShowError'
// Services & Utils
import { Magento } from '../services/magento'
import { buildShippingAddressSchema } from '../utils/validations'
import { createUserAddress, updateUserAddress } from '../utils/addressHelpers'
import environment from '../utils/environment'
import {
  getFormattedDataFromPlaces,
  getMagentoRegionFromGooglePlaceRegion,
} from '../utils/googleHelpers'
// Styles
import { Container, ErrorMsg, StyledForm } from '../styles/LoginPage.styles'
import { QService } from '../services/q-services'

const ShippingAddress = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [defaultShipping, setDefaultShipping] = useState(false)
  const [countryData, setCountryData] = useState(null)

  // state passed from Link in `shipping-info` page {type: "add | update"}
  const { state: shippingInfoState } = location
  const returnUrl = shippingInfoState?.returnUrl || '/cart'

  const { updateMagentoUser, magentoUser, qUser, handleSetUserState } =
    useAuthContext()
  const [error, showError] = useShowError()

  const {
    localeCountryCode,
    prismicData: {
      prismicAddUpdateShippingPage: {
        add_address,
        address_1,
        address_2,
        city,
        state,
        first_name,
        last_name,
        loading_country_data,
        phone_number,
        save_changes,
        set_as_default_shipping_address,
        update_address,
        update_shipping_address,
        zip_code,
      },
      prismicAmbassadorEnroll,
    },
  } = usePrismic()

  useEffect(() => {
    // if no shipping state passed to page (ie. user typed in URL), then navigate out
    if (!shippingInfoState) {
      navigate(returnUrl)
      return
    }

    // sets default shipping checkbox
    setDefaultShipping(!!location?.state?.address?.default_shipping)
    ;(() =>
      Magento.Store.getCountryData({
        id: localeCountryCode.toUpperCase(),
      }).then(({ country }) => {
        setCountryData(country)
        setLoading(false)
      }))()
  }, [])

  const {
    clearErrors,
    handleSubmit,
    register,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildShippingAddressSchema(prismicAmbassadorEnroll)),
    defaultValues:
      shippingInfoState?.type === 'update'
        ? {
            address1: shippingInfoState.address.street[0],
            address2: shippingInfoState.address?.street[1] || '',
            city: shippingInfoState.address.city,
            firstname: shippingInfoState.address.firstname,
            lastname: shippingInfoState.address.lastname,
            postcode: shippingInfoState.address.postcode,
            state: `${shippingInfoState.address.region.region_id},${shippingInfoState.address.region.region_code},${shippingInfoState.address.region.region}`,
            country_code: shippingInfoState.address.country_code.toUpperCase(),
            telephone: shippingInfoState.address.telephone,
          }
        : {
            firstname: magentoUser?.firstname ?? '',
            lastname: magentoUser?.lastname ?? '',
            country_code: localeCountryCode.toUpperCase(),
          },
  })

  const onSubmit = async data => {
    setIsSubmitting(true)
    if (shippingInfoState.type === 'add') {
      createUserAddress(
        data,
        defaultShipping,
        updateMagentoUser,
        navigate,
        returnUrl
      )
    } else {
      const {
        address: { id },
      } = shippingInfoState
      updateUserAddress(
        id,
        data,
        defaultShipping,
        updateMagentoUser,
        navigate,
        returnUrl
      )
    }

    // GET THE TWO LETTER STATE FROM '123,UT,Utah'
    const getState = data.state.split(',')[1]

    const qsAddress = {
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: getState,
      zip: data.postcode,
    }

    const qsObj = {
      associateId: qUser.associateId,
      address: qsAddress,
      shippingAddress: qsAddress,
    }

    await QService.User.updateAssociate(qsObj).then(res => {
      console.log(res)
      handleSetUserState({
        qUser: { ...res },
      })
    })
  }

  if (loading)
    return <Loading loading={loading} message={loading_country_data[0].text} />

  return (
    <>
      <Seo title={update_shipping_address[0].text} />
      <PageHeader
        exitRoute="/shipping-info"
        state={{
          returnUrl,
        }}
      >
        {shippingInfoState?.type === 'update'
          ? update_address[0].text
          : add_address[0].text}
      </PageHeader>
      <Container padding="1em">
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container maxWidth="500px" justify="space-between" padding="0">
            <ErrorMsg>{errors.firstname?.message}</ErrorMsg>
            <ErrorMsg>{errors.lastname?.message}</ErrorMsg>
          </Container>
          {error && showError()}
          <Container maxWidth="500px" justify="space-between" padding="0">
            <StyledInput
              {...register('firstname')}
              placeholder={first_name[0].text}
              width="49%"
              autoFocus
            />
            <StyledInput
              {...register('lastname')}
              placeholder={last_name[0].text}
              width="49%"
            />
          </Container>
          <Container maxWidth="500px" justify="space-between" padding="0">
            <AutoComplete
              apiKey={environment.GOOGLE_MAP_API_KEY}
              autoFocus
              options={{
                componentRestrictions: { country: [localeCountryCode] },
                fields: ['address_components'],
                types: ['address'],
              }}
              style={{
                padding: '10px',
                width: '100%',
                maxWidth: '500px',
                border: '1px solid #222',
                borderRadius: '10px',
                marginBottom: '1rem',
                fontSize: '16px',
              }}
              onPlaceSelected={place => {
                const formattedPlace = getFormattedDataFromPlaces(place)
                setValue('address1', formattedPlace.address1)
                setValue('city', formattedPlace.locality)
                setValue(
                  'state',
                  getMagentoRegionFromGooglePlaceRegion(
                    countryData.available_regions,
                    formattedPlace.state,
                    formattedPlace.country
                  )
                )
                setValue('postcode', formattedPlace.postcode)
                clearErrors()
                setFocus('telephone')
              }}
            />
          </Container>
          <Container maxWidth="500px" direction="column" padding="0">
            <ErrorMsg>{errors.address1?.message}</ErrorMsg>
            <StyledInput
              {...register('address1')}
              placeholder={address_1[0].text}
            />
          </Container>

          <Container maxWidth="500px" direction="column" padding="0">
            <ErrorMsg>{errors.address2?.message}</ErrorMsg>
            <StyledInput
              {...register('address2')}
              placeholder={address_2[0].text}
            />
          </Container>

          <Container maxWidth="500px" direction="column" padding="0">
            <ErrorMsg>{errors.city?.message}</ErrorMsg>
            <StyledInput {...register('city')} placeholder={city[0].text} />
          </Container>

          <Container maxWidth="500px" justify="space-between" padding="0">
            <ErrorMsg>{errors.state?.message}</ErrorMsg>
            <ErrorMsg>{errors.postcode?.message}</ErrorMsg>
          </Container>
          <Container maxWidth="500px" justify="space-between" padding="0">
            <StyledSelect {...register('state')} width="49%">
              <option value="">{state[0].text}</option>
              {countryData &&
                countryData.available_regions.map(({ id, code, name }) => (
                  <option key={id} value={`${id},${code},${name}`}>
                    {name}
                  </option>
                ))}
            </StyledSelect>
            <StyledInput
              {...register('postcode')}
              placeholder={zip_code[0].text}
              width="49%"
            />
          </Container>

          <Container maxWidth="500px" direction="column" padding="0">
            <ErrorMsg>{errors.country_code?.message}</ErrorMsg>
            <CountryDropdown {...register('country_code')} />
          </Container>

          <Container maxWidth="500px" direction="column" padding="0">
            <ErrorMsg>{errors.telephone?.message}</ErrorMsg>
            <StyledInput
              {...register('telephone')}
              placeholder={phone_number[0].text}
              type="telephone"
            />
          </Container>

          <Container maxWidth="500px" direction="column" padding="0">
            <Checkbox
              style={{ alignSelf: 'flex-start' }}
              name="default_shipping"
              value="default_shipping"
              label={{
                children: (
                  <Container padding="0">
                    <span>{set_as_default_shipping_address[0].text}</span>
                  </Container>
                ),
              }}
              checked={defaultShipping}
              onChange={() => setDefaultShipping(!defaultShipping)}
            />
          </Container>

          <SaveButton
            type="submit"
            disabled={Object.entries(errors).length !== 0 || isSubmitting}
          >
            {save_changes[0].text}
          </SaveButton>
        </StyledForm>
      </Container>
    </>
  )
}

export default ShippingAddress
